import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }
  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Spa Cleaning Services | Hotel Cleaning | HCS"
        description="For five-star spa cleaning services, choose a reliable option. Our professional, dedicated staff provides top-tier service for spas nationwide."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-48 md:pt-64 lg:pt-80 xl:pt-[490px] pb-14 md:pb-52 lg:pb-[415px] relative mb-8 lg:mb-0">
        <BgImage
          className="inset-0 w-full h-full bg-center"
          image={heroImages}
          style={{ position: "absolute" }}
        />
        <div className="container relative">
          <h1 className="mb-4 md:mb-4 md:max-w-[560px]">
            Spa Cleaning Services
          </h1>
          <p className="md:text-xl mb-0 md:max-w-sm">
            For the health and safety of your guests.
          </p>
        </div>
      </section>

      <LogoCloud className="relative lg:-top-60 mb-16 md:-mb-6" />

      <section className="relative mb-20 md:mb-32">
        <div className="absolute top-0 w-full"></div>
        <div className="relative container">
          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-24">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4">
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="About"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="What We Clean"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Benefits"
                  stripHash
                  className="no-underline"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <StyledContent>
                <div id="anchor-1" className="mb-14 lg:mb-16">
                  <h2>Only the Best</h2>
                  <p>
                    Your guests expect not only a soothing, relaxing spa
                    treatment but also that their overall experience is
                    impeccably clean and sanitary. That includes the spa locker
                    rooms, treatment rooms, and relaxation lounges. Our team of
                    professional, dedicated staff provides top-tier service to
                    ensure your spa meets the high expectations of your guests.
                    You no longer have to worry about your public spaces and
                    back of house spaces being clean, sanitized, and ready for
                    work every morning.
                  </p>
                </div>

                <div id="anchor-2" className="mb-14 lg:mb-16">
                  <h2 className="text-mobile-4xl md:text-4xl">What We Clean</h2>
                  <p>
                    At Hotel Cleaning Services, we understand every spa facility
                    will have specific cleaning requirements. For this reason,
                    we deliver a full scope of customized services for the
                    various areas of your hotel or resort spa, including:
                  </p>
                  <ul>
                    <li>Reception Area</li>
                    <li>Stations</li>
                    <li>Treatment Rooms</li>
                    <li>Locker Rooms</li>
                    <li>Waiting Areas</li>
                    <li>Private Relaxation/Meditation Areas</li>
                    <li>Showers & Baths</li>
                    <li>Aquatic Area</li>
                    <li>Fitness and Aerobics Studios</li>
                    <li>Beauty Salon</li>
                  </ul>
                </div>

                <div id="anchor-3">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    The Benefits of Outsourced Spa Cleaning
                  </h2>
                  <h3>Decades of Experience in Hospitality</h3>
                  <p>
                    Over 30 years of experience cleaning luxury hotels and
                    resorts has given our company time to perfect our
                    techniques. We guarantee you and your spa guests will be
                    impressed by the level of cleanliness and professionalism we
                    bring to the facility.
                  </p>

                  <h3>Highly-Trained Staff</h3>
                  <p>
                    We perform a thorough vetting process for all staff members.
                    Our team conducts background checks, reference checks, and
                    comprehensive interviews. You can trust that our staff will
                    be well-suited for cleaning your spa facility.
                  </p>

                  <h3>The Proper Tools for High-Quality Results</h3>
                  <p>
                    Having properly trained employees is only half the equation
                    when it comes to high-quality housekeeping. Access to the
                    right equipment is an essential part of what we offer to
                    make your spa clean and sanitary for your guests.
                  </p>
                </div>
              </StyledContent>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <CallToAction
        heading={["Contact ", <span className="text-primary-500">Us</span>]}
        subtext="Our highly skilled resort cleaning team is ready to exceed your expectations. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/SPA Cleaning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/SPA Cleaning.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: { eq: "services/2.7 Spa Cleaning/1.0 SPA Hero Desktop.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(
      relativePath: { eq: "services/2.7 Spa Cleaning/1.0 SPA Hero mobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
